define("homepage4/routes/events", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class EventsRoute extends _route.default {
    model() {
      return [{
        'announcement': 'Vorläufige Terminliste 2023',
        'events': [{
          'date': '13.03.',
          'text': 'Hauptversammlung',
          'class': 'local-event'
        }, {
          'date': '01.11.',
          'text': 'Herbstprüfung',
          'class': 'local-event'
        }]
      }];
    }

  }

  _exports.default = EventsRoute;
});