define("homepage4/templates/location", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hOzhdYMl",
    "block": "[[[10,\"section\"],[14,0,\"container mx-auto mt-4\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"text-2xl text-red-500\"],[12],[1,\"So findet Ihr uns:\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Boxer-Klub e.V. der Gruppe Karlsruhe\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Kurzheckweg 19, 76187 Karlsruhe\"],[13],[1,\"\\n\\n\"],[13],[1,\"\\n  \"],[10,\"iframe\"],[14,\"title\",\"location\"],[14,\"src\",\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2616.233651566391!2d8.337193851421217!3d49.02516297920254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797072ac5e6163b%3A0xdaaa28298a7baa04!2sBoxer-Klub%20e.V.%20der%20Gruppe%20Karlsruhe!5e0!3m2!1sen!2sde!4v1579378112265!5m2!1sen!2sde\"],[14,0,\"object-contain w-full\"],[14,\"frameborder\",\"0\"],[14,\"allowfullscreen\",\"\"],[12],[1,\"   \"],[13],[1,\"\\n\"],[46,[28,[37,1],null,null],null,null,null],[1,\"  \"]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "homepage4/templates/location.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});