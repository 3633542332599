define("homepage4/templates/members", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "HEkpSyxm",
    "block": "[[[10,\"section\"],[14,0,\"container mx-auto mt-4\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"text-2xl text-red-500\"],[12],[1,\"Eine Übersicht der aktiven Hundeführer\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
    "moduleName": "homepage4/templates/members.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});