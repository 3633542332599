define("homepage4/components/news-page", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each @news as |item|}}
      <div class="flex mb-4">
          <div class="text-black  w-1/2">{{item.label}}</div>
          <div class="w-1/2">
              {{#if item.ressource}}
              <div>
                  <a href="{{item.ressource}}" download>pdf download</a>
              </div>
              {{/if}}
              {{#if item.text}}
              {{item.text}}
              {{/if}}
              {{#if item.link}}
                  <a class="underline" href="{{item.link}}">{{item.linkLabel}}</a>
              {{/if}}
              {{#if item.mail}}
                  <a class="underline" href="mailto:{{item.mail}}">{{item.mail}}</a>
              {{/if}}
          </div>
      </div>
  {{/each}}
  {{yield}}
  */
  {
    "id": "Ze74Mx3Z",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"flex mb-4\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"text-black  w-1/2\"],[12],[1,[30,2,[\"label\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"w-1/2\"],[12],[1,\"\\n\"],[41,[30,2,[\"ressource\"]],[[[1,\"            \"],[10,0],[12],[1,\"\\n                \"],[10,3],[15,6,[29,[[30,2,[\"ressource\"]]]]],[14,\"download\",\"\"],[12],[1,\"pdf download\"],[13],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[41,[30,2,[\"text\"]],[[[1,\"            \"],[1,[30,2,[\"text\"]]],[1,\"\\n\"]],[]],null],[41,[30,2,[\"link\"]],[[[1,\"                \"],[10,3],[14,0,\"underline\"],[15,6,[29,[[30,2,[\"link\"]]]]],[12],[1,[30,2,[\"linkLabel\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,2,[\"mail\"]],[[[1,\"                \"],[10,3],[14,0,\"underline\"],[15,6,[29,[\"mailto:\",[30,2,[\"mail\"]]]]],[12],[1,[30,2,[\"mail\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[2]],null],[18,3,null]],[\"@news\",\"item\",\"&default\"],false,[\"each\",\"-track-array\",\"if\",\"yield\"]]",
    "moduleName": "homepage4/components/news-page.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());

  _exports.default = _default;
});