define("homepage4/routes/news", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NewsRoute extends _route.default {
    model() {
      return [{
        'label': 'Anpassungen des Übungsbetriebes',
        'text': 'Neben dem Training in den Abteilungen A und B findet nun zur Vorbereitung auf Abteilung C auch ein Impulskontrolltraining statt.'
      }];
    }

  }

  _exports.default = NewsRoute;
});