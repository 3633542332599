define("homepage4/templates/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "OVWxNpuN",
    "block": "[[[10,\"section\"],[14,0,\"container mx-auto mt-4\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"text-2xl text-red-500\"],[12],[1,\"Willkommen auf der Homepage des BK-Karlsruhe.\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"section\"],[14,0,\"container mx-auto mt-4\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-1xl text-red-500\"],[12],[1,\"Aktuelle Informationen\"],[13],[1,\"\\n  \"],[8,[39,0],null,[[\"@news\"],[[30,0,[\"model\"]]]],[[\"default\"],[[[],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"news-page\",\"component\",\"-outlet\"]]",
    "moduleName": "homepage4/templates/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});