define("homepage4/routes/index", ["exports", "homepage4/routes/news"], function (_exports, _news) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends _news.default {}

  _exports.default = IndexRoute;
});