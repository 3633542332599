define("homepage4/templates/events", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "973nxw1m",
    "block": "[[[10,\"section\"],[14,0,\"container mx-auto mt-4\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"text-2xl text-red-500\"],[12],[1,\"Termine\"],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[10,\"h2\"],[14,0,\"text-1xl text-red-500\"],[12],[1,[30,2,[\"announcement\"]]],[13],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2,[\"events\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"flex mb-4\"],[12],[1,\"\\n        \"],[10,0],[15,0,[29,[\"text-black \",[52,[30,3,[\"class\"]],\"text-teal-500\"],\" w-1/2\"]]],[12],[1,[30,3,[\"date\"]]],[13],[1,\"\\n        \"],[10,0],[14,0,\"w-1/2\"],[12],[1,[30,3,[\"text\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[3]],null]],[2]],null],[13],[1,\"\\n\"],[46,[28,[37,4],null,null],null,null,null]],[\"@model\",\"schedule\",\"event\"],false,[\"each\",\"-track-array\",\"if\",\"component\",\"-outlet\"]]",
    "moduleName": "homepage4/templates/events.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});