define("homepage4/templates/board", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "hekDDv07",
    "block": "[[[10,\"section\"],[14,0,\"container mx-auto mt-4 text-justified flex-wrap\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"text-2xl text-red-500\"],[12],[1,\"Vorstand\"],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-1xl text-red-500\"],[12],[1,\"1. Vorsitzende\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n      Heike Hanewinckel\\n  \"],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-1xl text-red-500\"],[12],[1,\"2. Vorsitzende\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n      Jana Kunzmann\\n  \"],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-1xl text-red-500\"],[12],[1,\"Schriftwartin\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n      Birgit Grözinger\\n  \"],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-1xl text-red-500\"],[12],[1,\"Kassier\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n      Michael Grözinger\\n  \"],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-1xl text-red-500\"],[12],[1,\"Ausbildungswartin\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n      Christine Gutsch\\n  \"],[13],[1,\"\\n  \"],[10,\"h2\"],[14,0,\"text-1xl text-red-500\"],[12],[1,\"Beisitzende\"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n      Nathalie Hanewinckel\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n      Natascha Neef\\n  \"],[13],[1,\"\\n  \"],[10,2],[12],[1,\"\\n      Martin Rohrhirsch\\n  \"],[13],[1,\"\\n  \"],[13]],[],false,[]]",
    "moduleName": "homepage4/templates/board.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});