define("homepage4/routes/trainings", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TrainingsRoute extends _route.default {
    model() {
      return [{
        'day': 'Montags',
        'time': '19:00 Uhr',
        'text': 'Unterordnung'
      }, {
        'day': 'Donnerstags',
        'time': '18:00 Uhr',
        'text': 'Unterordnung und Schutzdienst'
      }];
    }

  }

  _exports.default = TrainingsRoute;
});